import React from 'react';
import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import ImageMap from 'assets/images/top/facts/animation-map.png';
import ImageMapPin1 from 'assets/images/top/facts/animation-map-pin1.png';
import ImageMapPin2 from 'assets/images/top/facts/animation-map-pin2.png';
import ImageMapPin3 from 'assets/images/top/facts/animation-map-pin3.png';
import ImageMapPin4 from 'assets/images/top/facts/animation-map-pin4.png';
import { mq } from 'style/variables';

const slideBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  5% {
    opacity: 1;
  }
  15% {
    opacity: 1;
    transform: translateY(0);
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

export const pin = (image: string, width: number, height: number, top: number, left: number, delay: number) => {
  return css`
    width: ${width}px;
    height: ${height}px;
    position: absolute;
    top: ${top}px;
    left: ${left}px;
    background-image: url(${image});
    background-size: contain;
    animation-name: ${slideBottom};
    animation-duration: 1.8s;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-delay: ${delay}s;
  `;
};

const Container = styled.div`
  margin-top: 3px;
  width: 176px;
  height: 176px;
  background-image: url(${ImageMap});
  background-size: contain;
  position: relative;
  ${mq.onlysp} {
    transform: scale(calc(180 / 176));
    margin-top: 0;
  }
`;

const Pin1 = styled.div`
  ${pin(ImageMapPin1, 60, 72, 40, 22, 0)}
`;

const Pin2 = styled.div`
  ${pin(ImageMapPin2, 43, 53, 9, 86, 0.1)}
`;

const Pin3 = styled.div`
  ${pin(ImageMapPin3, 47, 64, 89, 91, 0.2)}
`;

const Pin4 = styled.div`
  ${pin(ImageMapPin4, 38, 49, 46, 129, 0.3)}
`;

const AnimationMap: React.FC = () => {
  return (
    <Container>
      <Pin1 />
      <Pin2 />
      <Pin3 />
      <Pin4 />
    </Container>
  );
};

export default AnimationMap;
