import styled from '@emotion/styled';
import { ProjectEvaluateNumCardNumberSection } from 'components/molecules/projectEvaluateNumCard';
import FadeUpItem from 'components/organisms/fadeUpItem';
import React from 'react';
import { text } from 'style/mixin';
import { baseTransitionSecond, mq } from 'style/variables';
import { useIsSp } from 'util/useIsSp';

type TopFactType = '0' | '1' | '2' | '3' | '4' | '5';

interface TopFactProps {
  mainImage: React.ReactNode;
  title: React.ReactNode;
  counts: number;
  unitPre?: string;
  unit: any;
  subImage1: string;
  subImage2?: string;
  subImage1Count: number;
  subImage2Count: number;
  type: TopFactType;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  white-space: nowrap;
  gap: 16px;
  position: relative;
  color: #1a1311;
  ${mq.onlysp} {
    align-items: center;
    width: 100%;
    margin-bottom: 22px;
    gap: 14px;
    &:nth-of-type(2n) {
      margin-bottom: 64px;
    }
  }
  .main-image {
    width: 176px;
    height: 176px;
    ${mq.onlysp} {
      width: 180px;
      height: 180px;
    }
  }
  .sub-images {
    display: flex;
    ${mq.onlysp} {
      display: none;
    }
  }
  .sub-image-1 {
    display: grid;
    align-items: end;
    width: calc(53px * 2);
    div {
      width: 53px;
    }
    img {
      width: 100%;
    }
  }
  .sub-image-2 {
    display: grid;

    div {
      width: 25px;
    }

    img {
      width: 100%;
    }
  }
  ${mq.onlypc} {
    &.type-0,
    &.type-1,
    &.type-2,
    &.type-3 {
      width: 25%;
    }

    &.type-4,
    &.type-5 {
      width: 50%;
      flex-direction: row-reverse;

      .fact-body {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    &.type-1,
    &.type-3 {
      flex-direction: column-reverse;
      align-items: end;
      justify-content: flex-end;
    }

    &.type-0 {
      .title-count {
        padding-left: 6px;
      }
      .sub-images {
      }
      .sub-image-1 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        width: calc(53px * 2);
      }
      .sub-image-2 {
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: repeat(2, 1fr);
        height: auto;
        width: calc(25px * 10);
      }
    }
    &.type-1 {
      padding-right: 6px;
      .title-count {
        // XXX: 後でなんとかしたい
        div > div > div > div:has(.unit-plus) {
          margin-left: 6px;
        }
      }
      .sub-images {
        flex-direction: row-reverse;
      }
      .sub-image-1 {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        width: calc(63px * 1);
        div {
          width: 63px;
        }
      }
      .sub-image-2 {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        column-gap: 4px;
        justify-items: end;

        height: auto;
        width: calc(28px * 4);
        margin-right: 18px;
        div {
          width: 28px;
        }
      }
    }
    &.type-2 {
      padding-left: 97px;
      .sub-images {
        flex-direction: column;
      }
      .sub-image-1 {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(1, 1fr);
        width: calc(44px * 5);
        margin-top: -9px;
        div {
          width: 44px;
        }
      }
      .sub-image-2 {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(1, 1fr);
        height: auto;
        width: calc(30px * 6);
        div {
          width: 30px;
        }
      }
    }
    &.type-3 {
      padding-right: 14px;
      .main-image {
        margin-right: -8px;
        margin-top: -19px;
      }
      .sub-images {
        flex-direction: column;
        padding-left: 4px;
      }
      .sub-image-1 {
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(1, 1fr);
        width: calc(33px * 7);
        column-gap: 1px;
        div {
          width: 33px;
        }
      }
      .sub-image-2 {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        height: auto;
        width: calc(22px * 3);
        column-gap: 3px;
        div {
          width: 22px;
        }
      }
    }
    &.type-4 {
      justify-content: flex-end;
      align-items: flex-start;
      padding-left: 2px;
      .main-image {
        position: absolute;
        bottom: 18px;
        left: 200px;
        width: 208px;
        height: 208px;
      }
      .fact-body {
        gap: 23px;
      }
      .sub-images {
        flex-direction: column-reverse;
      }
      .sub-image-1 {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        width: calc(59px * 4);
        div {
          width: 59px;
        }
      }
      .sub-image-2 {
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(1, 1fr);
        height: auto;
        width: calc(44px * 8);
        margin-bottom: 2px;
        div {
          width: 44px;
        }
      }
    }
    &.type-5 {
      align-items: flex-start;
      gap: 10px;
      .main-image {
        width: 203px;
        height: 208px;
        margin-top: 8px;
      }
      .sub-images {
        flex-direction: row;
        gap: 5px;
      }
      .fact-body {
        padding-top: 4px;
      }
      .title-count {
        position: absolute;
        bottom: 0;
        right: 70px;
        width: 334px;
      }
      .sub-image-1 {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        width: calc((67px + 7px) * 4);
        row-gap: 10px;
        column-gap: 7px;
        div {
          width: 67px;
        }
      }
      .sub-image-2 {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        height: fit-content;
        width: calc((46px + 4px) * 4);
        column-gap: 4px;
        row-gap: 6px;
        div {
          width: 46px;
        }
      }
      .unit-alphabet {
        font-size: 78px;
      }
    }
  }
  ${mq.onlysp} {
    &.type-3 {
      .fact-body {
        margin-left: 36px;
      }
    }
  }
`;

const Title = styled.p`
  font-family: 'toppan-bunkyu-midashi-go-std', sans-serif;
  ${text(18, 1.2)}
  font-weight: 900;
  letter-spacing: 0.15em;
  margin-bottom: 12px;

  .type-3 & {
    letter-spacing: 0.1em;
  }
`;

const Counts = styled.div`
  font-size: 96px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: -1.92px;
  margin-bottom: 6px;
  display: flex;
`;

const UnitPre = styled.span`
  writing-mode: vertical-rl;
  font-family: 'toppan-bunkyu-midashi-go-std', sans-serif;
  font-size: 34px;
  font-weight: 900;
  line-height: 109%; /* 37.06px */
  letter-spacing: 3.64px;
  text-align: end;
  padding-bottom: 6px;
  padding-right: 6px;
  ${mq.onlysp} {
    padding-right: 2px;
    padding-bottom: 0;
  }
`;

export const TopFact: React.FC<TopFactProps> = ({
  mainImage,
  title,
  counts,
  unitPre,
  unit,
  subImage1,
  subImage2,
  subImage1Count,
  subImage2Count,
  type,
}) => {
  const isSP = useIsSp();
  const _subImage2 = subImage2 ? subImage2 : '';
  const baseDelay = isSP ? 0 : (Number(type) % 2) * 0.5 - 0.5;
  const delayMainImage = isSP ? 0.5 : 1.9 + baseDelay;
  const delayImages = 0.9 + baseDelay;
  const delayCountUp = isSP ? 0.5 : 0.9 + baseDelay;
  const subImageDelayRatio = 2.5;
  return (
    <Container className={`type-${type}`}>
      <div className="main-image">
        <FadeUpItem delay={delayMainImage + baseTransitionSecond / 3} invokeInView extraDuration={0.2}>
          {mainImage}
        </FadeUpItem>
      </div>

      <div className="fact-body">
        <div className="title-count">
          <FadeUpItem delay={delayCountUp} invokeInView>
            <Title>{title}</Title>
            <Counts>
              {unitPre && <UnitPre>{unitPre}</UnitPre>}

              <ProjectEvaluateNumCardNumberSection
                size={['4', '5'].includes(type) ? 'xlarge' : 'large'}
                number={counts.toString()}
                unit={unit || ''}
                delay={delayCountUp}
              />
            </Counts>
          </FadeUpItem>
        </div>
        <div className="sub-images">
          <div className="sub-image-1">
            {Array.from({ length: subImage1Count }).map((_, index) => (
              <div key={index}>
                <FadeUpItem
                  delay={delayImages + (index * baseTransitionSecond) / subImageDelayRatio}
                  invokeInView
                  fadeScaleUp
                >
                  <img src={subImage1} alt="" />
                </FadeUpItem>
              </div>
            ))}
          </div>
          <div className="sub-image-2">
            {Array.from({ length: subImage2Count }).map((_, index) => (
              <div key={index}>
                <FadeUpItem
                  delay={
                    baseDelay + delayImages + ((index + subImage1Count) * baseTransitionSecond) / subImageDelayRatio
                  }
                  invokeInView
                  fadeScaleUp
                >
                  <img src={_subImage2} alt="" />
                </FadeUpItem>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export const TopFactOrganizer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px; */
  width: 100%;

  /* & > *:nth-of-type(5) {
    grid-column: 1 / span 2;
  }
  & > *:nth-of-type(6) {
    grid-column: 3 / span 2;
  } */
`;
