import React from 'react';
import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import ImageServicesHand from 'assets/images/top/facts/animation-services-hand.png';
import ImageServicesBag from 'assets/images/top/facts/animation-services-bag.png';
import ImageServicesUser from 'assets/images/top/facts/animation-services-user.png';
import ImageServicesCircle from 'assets/images/top/facts/animation-services-circle.png';
import ImageServicesWifi from 'assets/images/top/facts/animation-services-wifi.png';
import { mq } from 'style/variables';

const slideTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  25% {
    opacity: 1;
    transform: translateY(0);
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

export const item = (image: string, width: number, height: number, top: number, left: number, delay: number) => {
  return css`
    width: ${width}px;
    height: ${height}px;
    position: absolute;
    top: ${top}px;
    left: ${left}px;
    background-image: url(${image});
    background-size: contain;
    animation-name: ${slideTop};
    animation-duration: 1.8s;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-delay: ${delay}s;
  `;
};

const Container = styled.div`
  width: 208px;
  height: 208px;
  position: relative;
  ${mq.onlysp} {
    transform: scale(calc(180 / 208));
    margin-left: -16px;
  }
`;

const Hand = styled.div`
  width: 188px;
  height: 86px;
  position: absolute;
  top: 119px;
  left: 0px;
  background-image: url(${ImageServicesHand});
  background-size: contain;
`;

const Bag = styled.div`
  ${item(ImageServicesBag, 59, 60, 73, 115, 0)}
`;

const User = styled.div`
  ${item(ImageServicesUser, 73, 76, 3, 83, 0.2)}
`;

const Circle = styled.div`
  ${item(ImageServicesCircle, 64, 64, 70, 49, 0.1)}
`;

const Wifi = styled.div`
  ${item(ImageServicesWifi, 66, 65, 15, 21, 0.3)}
`;

const AnimationServices: React.FC = () => {
  return (
    <Container>
      <Hand />
      <Bag />
      <User />
      <Circle />
      <Wifi />
    </Container>
  );
};

export default AnimationServices;
