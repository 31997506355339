import React, { useState } from 'react';
import styled from '@emotion/styled';
import { mq } from 'style/variables';
import colors from 'style/colors';
import { ShifterProjectsJpDataAcfHighlightsContentsValue } from 'types/graphql-types';
import { NonNullableFields } from 'util/types';
import fonts from 'style/fonts';
import { css } from '@emotion/core';
import { useCountUp } from 'use-count-up';
import { InView } from 'react-intersection-observer';

export type ProjectEvaluateNumCardProps = NonNullableFields<ShifterProjectsJpDataAcfHighlightsContentsValue> & {
  hasNoteIcon?: {
    unit?: boolean;
    text?: boolean;
  };
} & {
  // 単位をカスタムでつけるため. トップではunitがReact Nodeになることがあるため
  unit?: React.ReactNode;
};

type Size = 'large' | 'xlarge';

const ProjectEvaluateNumCardContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 60px 48px;
  background-color: ${colors.black02};
  color: ${colors.white01};
  ${mq.onlysp} {
    padding: 32px;
  }
`;

const ProjectEvaluateNumCardNumberArea = styled.div`
  display: flex;
  align-items: baseline;
  line-height: 1em;
  white-space: nowrap; // FIXME: AeonWaleltで段落ちしてしまう問題への応急処置
`;

const ProjectEvaluateNumCardNumber = styled.div<{ size?: Size }>`
  font-family: ${fonts.enGothic};
  letter-spacing: ${({ size }) => (size === 'large' ? -0.02 : size === 'xlarge' ? -0.02 : -0.008)}em;
  font-size: ${({ size }) => (size === 'large' ? 96 : size === 'xlarge' ? 114 : 89)}px;
  margin-left: ${({ size }) => (size === 'large' ? -4 : size === 'xlarge' ? -4 : 0)}px;
  margin-top: ${({ size }) => (size === 'xlarge' ? -3 : 0)}px;
  font-weight: 700;
  line-height: 1em;
  small {
    font-size: 64px;
  }
  sup {
    font-size: 24px;
  }

  ${mq.onlysp} {
    line-height: 1em;
    font-size: ${({ size }) => (size === 'large' ? 80 : size === 'xlarge' ? 80 : 64)}px;
  }
`;

const ProjectEvaluateNumCardUnit = styled.div<Pick<ProjectEvaluateNumCardProps, 'hasNoteIcon'> & { size?: Size }>`
  position: relative;
  font-size: ${({ size }) => (size === 'large' ? 38 : size === 'xlarge' ? 46 : 40)}px;
  padding-bottom: ${({ size }) => (size === 'large' ? 10 : size === 'xlarge' ? 10 : 0)}px;
  font-family: 'Unbounded-custom', 'toppan-bunkyu-midashi-go-std', sans-serif;
  font-weight: 600;
  line-height: 1em;
  align-self: stretch;
  display: flex;
  align-items: flex-end;
  ${mq.onlysp} {
    font-size: ${({ size }) => (size === 'large' ? 32 : size === 'xlarge' ? 32 : 24)}px;
  }
  &:not(:has(.unit-alphabet)) {
    padding-left: ${({ size }) => (size === 'large' ? 10 : size === 'xlarge' ? 10 : 2)}px;
    padding-bottom: ${({ size }) => (size === 'large' ? 10 : size === 'xlarge' ? 10 : 7)}px;
  }

  .unit-alphabet {
    font-size: 66px;
    font-weight: 800;
    line-height: 84%; /* 55.44px */
    letter-spacing: -1.98px;
    padding-right: 10px;
    ${mq.onlysp} {
      font-size: 56px;
    }
  }

  .unit-plus {
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 63%; /* 27.72px */
    position: absolute;
    bottom: 60px;
    right: 18px;
    ${mq.onlysp} {
      font-size: 36px;
      bottom: 52px;
    }
  }

  .unit-vertical {
    writing-mode: vertical-rl;
    letter-spacing: -1.52px;
    display: block;
    padding-left: 10px;
    height: 77px;
    margin-left: -12px;
    ${mq.onlysp} {
      height: 62px;
    }
  }

  .unit-per {
    font-size: 14px;
    font-weight: 400;
    line-height: 82%; /* 11.48px */
    letter-spacing: 0.28px;
    // unit-perクラスはTOPでしか使用されていないため、かなり限定的で強引なスタイル調整をここで行なう
    margin: 7px 0 6px -5px;
    display: block;
    ${mq.onlysp} {
      font-size: 20px;
    }
  }
  &:after {
    ${({ hasNoteIcon }) =>
      hasNoteIcon?.unit &&
      css`
        content: '*';
        font-family: ${fonts.jaGothic};
        font-weight: 400;
        font-size: 10px;
        position: absolute;
        top: -9px;
        right: 5px;

        ${mq.onlysp} {
          top: 2px;
        }
      `}
    ${({ hasNoteIcon }) =>
      hasNoteIcon?.unit &&
      hasNoteIcon?.text &&
      css`
        content: '*1';
      `}
  }
`;

const ProjectEvaluateNumCardText = styled.div`
  position: relative;
  display: inline-block;
  ${mq.onlysp} {
    margin-top: 6px;
  }
`;

const ProjectEvaluateNumCardTextInner = styled.div<Pick<ProjectEvaluateNumCardProps, 'hasNoteIcon'>>`
  font-family: ${fonts.jaGothic};
  font-size: 40px;
  font-weight: 600;
  line-height: 1.5em;
  sup {
    font-size: 16px;
  }
  ${mq.onlysp} {
    font-size: 24px;
  }

  &:after {
    ${({ hasNoteIcon }) =>
      hasNoteIcon?.text &&
      css`
        content: '*';
        font-family: ${fonts.jaGothic};
        font-weight: 400;
        font-size: 10px;
        display: inline-block;
        transform: translate(7px, -26px);

        ${mq.onlysp} {
          transform: translate(7px, -15px);
        }
      `}
    ${({ hasNoteIcon }) =>
      hasNoteIcon?.unit &&
      hasNoteIcon?.text &&
      css`
        content: '*2';
      `}
  }
`;

const ProjectEvaluateNumCardNotes = styled.div``;

const ProjectEvaluateNumCardNoteItem = styled.p`
  line-height: 1em;
  font-size: 12px;
  &::before {
    content: '※';
  }
`;

export const ProjectEvaluateNumCardNumberSection: React.FC<
  Pick<ProjectEvaluateNumCardProps, 'number' | 'unit' | 'hasNoteIcon'> & {
    size?: 'large' | 'xlarge';
    delay?: number;
  }
> = ({ number, unit, hasNoteIcon, size, delay }) => {
  const [isCounting, setIsCounting] = useState(false);

  const _number = parseFloat(number);

  const { value } = useCountUp({
    isCounting,
    start: _number - 56 > 0 ? _number - 56 : 0,
    end: _number,
    duration: 1.5,
    easing: 'easeOutCubic',
    thousandsSeparator: ',',
    decimalSeparator: '.',
  });

  return (
    <InView
      onChange={inView => {
        if (inView) {
          setTimeout(
            () => {
              setIsCounting(true);
            },
            delay ? delay * 1000 : 0
          );
        }
      }}
    >
      <ProjectEvaluateNumCardNumberArea>
        <ProjectEvaluateNumCardNumber size={size}>{value}</ProjectEvaluateNumCardNumber>
        {typeof unit === 'string' ? (
          <ProjectEvaluateNumCardUnit dangerouslySetInnerHTML={{ __html: unit }} hasNoteIcon={hasNoteIcon} />
        ) : (
          <ProjectEvaluateNumCardUnit hasNoteIcon={hasNoteIcon} size={size}>
            {unit}
          </ProjectEvaluateNumCardUnit>
        )}
      </ProjectEvaluateNumCardNumberArea>
    </InView>
  );
};

export const ProjectEvaluateNumCard: React.FC<ProjectEvaluateNumCardProps> = ({
  number,
  unit,
  text,
  notes,
  hasNoteIcon,
}) => {
  return (
    <ProjectEvaluateNumCardContainer>
      <ProjectEvaluateNumCardNumberSection number={number} unit={unit} hasNoteIcon={hasNoteIcon} />
      <ProjectEvaluateNumCardText>
        <ProjectEvaluateNumCardTextInner dangerouslySetInnerHTML={{ __html: text }} hasNoteIcon={hasNoteIcon} />
      </ProjectEvaluateNumCardText>
      {notes.length > 0 && (
        <ProjectEvaluateNumCardNotes>
          {notes.map((item, index) => (
            <ProjectEvaluateNumCardNoteItem key={index}>{item?.text}</ProjectEvaluateNumCardNoteItem>
          ))}
        </ProjectEvaluateNumCardNotes>
      )}
    </ProjectEvaluateNumCardContainer>
  );
};
