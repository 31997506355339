import React from 'react';
import baloonImg1 from 'assets/images/top/facts/animation-talk-baloon01.png';
import baloonImg2 from 'assets/images/top/facts/animation-talk-baloon02.png';
import personImg1 from 'assets/images/top/facts/animation-talk-person01.png';
import deskImg from 'assets/images/top/facts/animation-talk-desk.png';
import personImg2 from 'assets/images/top/facts/animation-talk-person02.png';
import styled from '@emotion/styled';
import { mq } from 'style/variables';

const Container = styled.div`
  position: relative;
  width: 176px;
  height: 176px;
  margin-top: -2px;
  @keyframes baloon {
    0% {
      opacity: 0;
      transform: translateY(5px) scale(0.95);
      transition-timing-function: cubic-bezier(0.12, 0, 0.39, 0);
    }
    20% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
    60% {
      opacity: 1;
      transform: translateY(0) scale(1);
      transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
    }
    75% {
      opacity: 0;
      transform: translateY(0) scale(1);
    }
    100% {
      opacity: 0;
      transform: translateY(2px) scale(0.8);
      transform: translateY(0);
    }
  }
  ${mq.onlysp} {
    margin: 0;
    transform: scale(calc(180 / 176));
  }
`;
const DeskImage = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  width: 176px;
  height: 176px;
`;

const PersonImage1 = styled.img`
  position: absolute;
  top: 28.6px;
  left: 14.1px;
  z-index: 1;
  width: 67.186px;
  height: 130.437px;
`;

const PersonImage2 = styled.img`
  position: absolute;
  top: 24.5px;
  left: 109px;
  z-index: -1;
  width: 56px;
  height: 99px;
`;

const BaloonImage1 = styled.img`
  position: absolute;
  top: 12.5px;
  left: 85.8px;
  z-index: 2;
  width: 50px;
  height: 33px;
  animation: baloon 1.8s infinite cubic-bezier(0.23, 1, 0.32, 1);
`;

const BaloonImage2 = styled.img`
  position: absolute;
  top: 51.7px;
  left: 59.4px;
  z-index: 3;
  width: 50px;
  height: 28px;
  animation: baloon 1.8s 0.25s infinite cubic-bezier(0.23, 1, 0.32, 1);
`;

const AnimationMtg: React.FC = () => {
  return (
    <Container>
      <DeskImage src={deskImg} alt="desk" />
      <PersonImage1 src={personImg1} alt="Person 1" />
      <PersonImage2 src={personImg2} alt="Person 2" />
      <BaloonImage1 src={baloonImg1} alt="Baloon 1" />
      <BaloonImage2 src={baloonImg2} alt="Baloon 2" />
    </Container>
  );
};
export default AnimationMtg;
