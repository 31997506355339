import styled from '@emotion/styled';
import { EnHeading } from 'components/atoms/enHeading';
import { TextButton } from 'components/atoms/textButton';
import { IndexLink } from 'components/molecules/indexLink';
import { ProjectCard } from 'components/molecules/projectCard';
import { StudioCard } from 'components/molecules/studioCard';
import { TopicsCard } from 'components/molecules/topicsCard';
import FadeUpSection from 'components/organisms/fadeUpSection';
import InquiryBlock from 'components/organisms/inquiryBlock';
import { LoopCarousel } from 'components/organisms/loopCarousel';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import ProjectCardCol from 'components/organisms/projectCardCol';
import Service from 'components/organisms/service';
import { TopicsGroup } from 'components/organisms/topicsGroup';
import { TopFact, TopFactOrganizer } from 'components/unique/topFacts';
import dayjs from 'dayjs';
import { graphql } from 'gatsby';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import { default as color, default as colors } from 'style/colors';
import fonts from 'style/fonts';
import { contentWrapper } from 'style/mixin';
import sizes from 'style/sizes';
import { baseTransitionSecond, breakpoint, mq } from 'style/variables';
import { IndexQuery } from 'types/graphql-types';

import Fig01 from 'assets/images/top/fig-01.png';
import Fig02 from 'assets/images/top/fig-02.png';
import Fig03 from 'assets/images/top/fig-03.png';
import Fig04 from 'assets/images/top/fig-04.png';
import Fig05 from 'assets/images/top/fig-05.png';
import Fig06 from 'assets/images/top/fig-06.png';
import AnimationMemo from 'components/unique/animationMemo';
import AnimationMtg from 'components/unique/animationMtg';
import AnimationAward from 'components/unique/animationAward';
import AnimationMap from 'components/unique/animationMap';
import AnimationServices from 'components/unique/animationServices';
import AnimationWalk from 'components/unique/animationWalk';
import { css } from '@emotion/core';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  location: {
    pathname: string;
  };
  data: IndexQuery;
}

const topmovie =
  'https://player.vimeo.com/video/968790245?h=69aaf994ae&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
const topmoviesp =
  'https://player.vimeo.com/video/969532758?h=08170d2539&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';

const ContentsWrapper = styled.div`
  max-width: 1860px;
  margin-right: auto;
  margin-left: auto;
`;
const TopContent = styled.div`
  overflow: hidden;
  position: relative;
  margin-bottom: ${sizes.margin[100]};
  ${mq.onlysp} {
    padding: 0;
    margin-bottom: ${sizes.margin[120]};
  }
`;

const MainVisualWrapper = styled.div`
  background-color: ${colors.black01};
  position: relative;
  margin-bottom: 106px;
  ${mq.onlysp} {
    margin-bottom: 53px;
  }
`;

const Mainvisual = styled.div`
  position: relative;
  ${mq.onlypc} {
    width: 100%;
    aspect-ratio: 16 / 9;
    margin: ${sizes.header.height.pc} 0 0;
    overflow: hidden;
  }
  ${mq.onlysp} {
    width: 100%;
    aspect-ratio: 3 / 4;
    margin: ${sizes.header.height.sp} 0 0;
    overflow: hidden;
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
`;

const MainvisualMovie = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  transition: opacity ${baseTransitionSecond}s ease-in-out;
  body.is-rendered & {
    opacity: 1;
  }

  & > div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  ${mq.onlypc} {
    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &.is-portrait {
      video {
        width: auto !important;
      }
    }
    &:not(.is-portrait) {
      video {
        height: auto !important;
      }
    }
  }
`;

const ScrollDown = styled('div')`
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  opacity: 0;
  transition: opacity ${baseTransitionSecond}s ease-in-out;
  ${mq.onlypc} {
    bottom: 0;

    &.is-show {
      opacity: 1;
    }
  }
  ${mq.onlysp} {
    bottom: -45px;
    &.is-show {
      opacity: 0;
    }
  }
`;

const ScrollTxt = styled('p')`
  position: relative;
  margin-bottom: 14px;
  font-family: ${fonts.enGothic};

  font-size: 1.5rem;
  height: 1.5rem;
  line-height: 1;
  letter-spacing: 1px;
  color: ${color.white01};
  ${mq.onlysp} {
    font-size: 8px;
    height: 8px;
    margin-bottom: 0;
    opacity: 0.5;
  }
  & > span {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    &:first-of-type {
      color: ${color.white01};
      opacity: 0;
      #mainvisual-scroll-down.is-show & {
        ${mq.onlypc} {
          opacity: 1;
        }
      }
    }
  }
`;

const LoadingText = styled('div')`
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  opacity: 1;
  transition: opacity ${baseTransitionSecond}s ease-in-out;

  body.is-rendered & {
    opacity: 0;
  }

  & > span {
    width: 100%;
    height: 1.5rem;
    font-family: ${fonts.enGothic};

    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 1px;
    color: ${color.gray07};

    animation: flash 1.6s ease-out infinite;
    @keyframes flash {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;

const ScrollBar = styled('span')`
  width: 1px;
  height: 54px;
  display: block;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #dbdbdb;
  overflow: hidden;
  opacity: 0;
  ${mq.onlysp} {
    display: none;
  }
  .is-show > & {
    opacity: 1;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 22px;
    background-color: ${colors.black01};
    left: 0;
    right: 0;
    top: 0;
    animation: scrollDown01 3s linear infinite;
  }
  @keyframes scrollDown01 {
    0% {
      top: -22px;
    }
    68% {
      top: 76px;
    }
    99% {
      top: 76px;
    }
    100% {
      bottom: -76px;
      top: auto;
    }
  }
`;

const SoundButton = styled.button`
  position: absolute;
  font-family: ${fonts.enGothic};

  opacity: 0;
  transition: opacity ${baseTransitionSecond}s 0.2s ease-in-out;
  body.is-rendered & {
    opacity: 1;
  }

  span {
    letter-spacing: 0.06em;
  }

  ${mq.onlypc} {
    bottom: 0;
    right: 46px;
  }
  ${mq.onlysp} {
    bottom: 0;
    right: 18px;
  }
`;

const SectionTitleWrap = styled.div`
  text-align: center;
  ${mq.onlypc} {
    margin-bottom: ${sizes.margin[48]};
  }
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[32]};
  }
`;

const TopFactsWrap = styled.div`
  ${contentWrapper}
  ${mq.onlysp} {
    margin-top: ${sizes.margin[96]};
    padding: 0;
    margin-top: 0;
  }
`;

const TopFactsTitle = styled.div`
  margin-top: 4px;
  ${mq.onlysp} {
    padding: 0 ${sizes.margin[32]};
  }
`;

const TopFactsTitleBody = styled(EnHeading)`
  font-weight: 600 !important;
  letter-spacing: -1.05px;
  ${mq.onlysp} {
    font-size: 40px;
    line-height: 93%;
    letter-spacing: 0;
  }
`;

const TopFactsSubTitle = styled.div`
  font-size: 22px;
  font-weight: 500;
  font-family: ${fonts.enGothic};
  margin-bottom: 73px;
  margin-top: 10px;
  letter-spacing: 0;
  ${mq.onlysp} {
    margin-top: 16px;
    font-size: 20px;
    line-height: 128%;
    margin-bottom: 58px;
  }
`;

const ServiceWrap = styled.div`
  ${contentWrapper}
  margin-top: ${sizes.margin[100]};
  ${mq.onlysp} {
    padding: 0;
    margin-top: ${sizes.margin[80]};
  }
`;

const ProjectsWrap = styled.div`
  ${contentWrapper}
  margin-top: ${sizes.margin[100]};
  ${mq.onlysp} {
    padding: 0;
    margin-top: ${sizes.margin[80]};
  }
`;

const TopicsWrap = styled.div`
  ${mq.onlypc} {
    ${contentWrapper}
    padding: 0;
    margin-top: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    margin-top: ${sizes.margin[80]};
  }
`;

const StudioWrap = styled.div`
  ${mq.onlypc} {
    margin-top: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    margin-top: ${sizes.margin[80]};
  }
`;

const ProjectTitle = styled('div')`
  width: 100%;
  margin: 0 auto;
  ${mq.onlysp} {
    padding: 0 ${sizes.margin[32]};
  }
`;

const TopicsTitle = styled('div')`
  ${contentWrapper}

  width: 100%;
  margin: 0 auto;
  ${mq.onlypc} {
    padding: 0;
  }
  ${mq.onlysp} {
    padding: 0;
  }
`;

const StudiosTitle = styled('div')`
  ${contentWrapper}

  width: 100%;
  margin: 0 auto;
  ${mq.onlysp} {
    padding: 0;
  }
`;

const ViewMoreLinkWrap = styled(IndexLink)`
  display: block;
  margin-top: ${sizes.margin[48]};
  text-align: center;

  ${({ to }) =>
    to === '/topics' &&
    css`
      margin-top: 26px;
    `}

  ${mq.onlysp} {
    margin-top: ${sizes.margin[16]};
    padding-bottom: 0;
  }
`;

const FactsAnd = styled.span`
  font-size: 28px;
  font-weight: 700;
  display: inline-block;
  margin: 0 6px;
  ${mq.onlysp} {
    margin: 0 -4px;
  }
`;

const Pages: React.FC<IndexPageProps> = ({ data }) => {
  const topics = data.allShifterTopicsData.edges;
  const studios = data.allShifterStudiosData.edges;
  const projectsOldLayout = data.projects.edges;
  const projectsNewLayout = data.projectsJp.edges;
  const projects = projectsNewLayout.concat(projectsOldLayout);
  const pickups = data.shifterPickupProjects?.acf?.pickup;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pickupProjects = pickups!
    .map(pickup => projects?.filter(project => project?.node?.title?.rendered === pickup?.post_title))
    .flat();
  const contents = useRef(null);
  const [isSp, setIsSp] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  // const timestamp = new Date().getTime();
  const movieW = 960;
  const movieH = 540;
  const resizeHandler = useCallback(() => {
    setIsSp(window.innerWidth <= breakpoint);
    setIsPortrait(window.innerWidth / window.innerHeight < movieW / movieH);
  }, []);

  const scrollHandler = useCallback(() => {
    if ((isSp ? window.innerWidth : window.innerHeight) > window.pageYOffset) {
      document.body.classList.add('is-pagetop');
    } else {
      document.body.classList.remove('is-pagetop');
    }
  }, []);

  let isStaticSp = false;
  if (typeof window !== 'undefined') isStaticSp = window.innerWidth <= breakpoint;

  useEffect(() => {
    document.body.classList.add('is-noscroll');
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    if ((isSp ? window.innerWidth : window.innerHeight) > window.pageYOffset) document.body.classList.add('is-pagetop');
    window.addEventListener('scroll', scrollHandler);

    return () => {
      document.body.classList.remove('is-pagetop');
      document.body.classList.remove('is-noscroll');
      document.body.classList.remove('is-rendered');

      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('scroll', scrollHandler);
      ParticleImageManager.delete();
    };
  }, []);

  const start = () => {
    document.getElementById('mainvisual-scroll-down')?.classList.add('is-show');
    document.body.classList.remove('is-noscroll');
    document.body.classList.add('is-rendered');
  };

  const onStart = () => {
    if (ParticleImageManager.isStart()) {
      start();
      return;
    }
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
        start();
      });
    });
  };

  const [videoMuted, toggleVideoMuted] = useState(true);

  return (
    <>
      <Helmet
        bodyAttributes={{
          'data-page': 'top',
        }}
      />
      <ContentsWrapper>
        <MainVisualWrapper>
          <Mainvisual>
            <LoadingText>
              <span>Loading...</span>
            </LoadingText>
            <MainvisualMovie className={isPortrait ? 'is-portrait' : undefined}>
              <ReactPlayer
                url={isSp ? topmoviesp : topmovie}
                playing={true}
                playsinline
                width={'100%'}
                height={'100%'}
                loop={true}
                onStart={onStart}
                volume={videoMuted ? 0 : 1}
                key={isSp ? 'sp' : 'pc'}
              />
            </MainvisualMovie>
          </Mainvisual>
          {/* <ScrollDown id="mainvisual-scroll-down">
            <ScrollTxt>
              <span>Scroll Down</span>
            </ScrollTxt>
            <ScrollBar></ScrollBar>
          </ScrollDown> */}

          {/* <SoundButton
            onClick={() => {
              toggleVideoMuted(!videoMuted);
            }}
          >
            <span>Sound</span>&nbsp;{videoMuted ? ' ON' : ' OFF'}
          </SoundButton> */}
        </MainVisualWrapper>

        <TopContent ref={contents}>
          <TopFactsWrap>
            <TopFactsTitle>
              <TopFactsTitleBody size="large">
                Facts <FactsAnd>&</FactsAnd>
                <br className="only-sp" /> Figures
              </TopFactsTitleBody>
              <TopFactsSubTitle>
                Showcasing <br className="only-sp" />
                Who We Are
              </TopFactsSubTitle>
            </TopFactsTitle>
            <TopFactOrganizer>
              <TopFact
                mainImage={<AnimationMemo />}
                title="アンケート回答総数"
                counts={290}
                unit={
                  <>
                    <span className="unit-alphabet">K</span>
                    <span className="unit-plus">+</span>人
                  </>
                }
                subImage1={Fig02}
                subImage2={Fig02}
                subImage1Count={2}
                subImage2Count={19}
                type={'0'}
              />
              <TopFact
                mainImage={<AnimationMtg />}
                title="ユーザーインタビュー実施総数"
                counts={1700}
                unit={
                  <>
                    <span className="unit-plus">+</span>人
                  </>
                }
                subImage1={Fig01}
                subImage2={Fig01}
                subImage1Count={1}
                subImage2Count={7}
                type={'1'}
              />
              <TopFact
                mainImage={<AnimationMap />}
                title="フィールドリサーチ"
                counts={56}
                unit={<span className="unit-vertical">都市</span>}
                subImage1={Fig04}
                subImage2={Fig04}
                subImage1Count={5}
                subImage2Count={6}
                type={'2'}
              />
              <TopFact
                mainImage={<AnimationWalk />}
                title="総移動距離"
                counts={63}
                unitPre="地球"
                unit={
                  <span>
                    周<span className="unit-per">/Year</span>
                  </span>
                }
                subImage1={Fig03}
                subImage2={Fig03}
                subImage1Count={7}
                subImage2Count={3}
                type={'3'}
              />
              <TopFact
                mainImage={<AnimationAward />}
                title="アワード受賞"
                counts={78}
                unit=""
                subImage1={Fig05}
                subImage2={Fig05}
                subImage1Count={7}
                subImage2Count={8}
                type={'4'}
              />
              <TopFact
                mainImage={<AnimationServices />}
                title={
                  <>
                    関わった
                    <br className="only-pc" />
                    サービス利用者総数
                  </>
                }
                counts={138}
                unit={
                  <>
                    <span className="unit-alphabet">M</span>
                    <span className="unit-plus">+</span>人
                  </>
                }
                subImage1={Fig06}
                subImage2={Fig06}
                subImage1Count={12}
                subImage2Count={7}
                type={'5'}
              />
            </TopFactOrganizer>
          </TopFactsWrap>

          <ServiceWrap>
            <Service top={true} />
          </ServiceWrap>

          <ProjectsWrap>
            <SectionTitleWrap>
              <ProjectTitle>
                <EnHeading>Projects</EnHeading>
              </ProjectTitle>
            </SectionTitleWrap>
            <ProjectCardCol col={2}>
              {pickupProjects?.map(project => (
                <FadeUpSection key={`project-${project.node.id}`}>
                  <ProjectCard
                    fromTop={true}
                    size={'large'}
                    imgpath={project.node.acf?.thumb || 'noimage/img-card-thumb.jpg'}
                    title={project.node.acf?.page_headline_ja || project.node.title?.rendered || 'No Title'}
                    client={project.node.acf?.client_abbr?.ja || undefined}
                    work={project?.node?.acf?.product?.ja || undefined}
                    tags={project.node.acf?.outline as string[]}
                    to={`/projects/${project.node.slug}`}
                  />
                </FadeUpSection>
              ))}
            </ProjectCardCol>
            <ViewMoreLinkWrap to={'/projects'}>
              <TextButton isBlock>Our Projects</TextButton>
            </ViewMoreLinkWrap>
          </ProjectsWrap>

          <TopicsWrap>
            <SectionTitleWrap>
              <TopicsTitle>
                <EnHeading>Topics</EnHeading>
              </TopicsTitle>
            </SectionTitleWrap>

            <FadeUpSection>
              <TopicsGroup spslide>
                {topics?.map(topics => {
                  return (
                    <TopicsCard
                      key={topics.node.id}
                      title={topics?.node.title?.rendered || 'NO TITLE'}
                      date={dayjs(topics?.node.acf?.launch || undefined).format('YYYY.MM.DD')}
                      to={`/topics/${topics?.node.slug}`}
                      imgpath={topics?.node.acf?.thumb || 'noimage/img-card-thumb.jpg'}
                      category={topics?.node.acf?.category || ''}
                    />
                  );
                })}
              </TopicsGroup>

              <ViewMoreLinkWrap to={'/topics'}>
                <TextButton isBlock>View More</TextButton>
              </ViewMoreLinkWrap>
            </FadeUpSection>
          </TopicsWrap>

          <StudioWrap>
            <SectionTitleWrap>
              <StudiosTitle>
                <EnHeading>Studios</EnHeading>
              </StudiosTitle>
            </SectionTitleWrap>

            <FadeUpSection>
              <LoopCarousel slidesToShow={2.4} padding={82}>
                {studios.map(studio => (
                  <StudioCard
                    key={studio.node.id}
                    imgpath={studio.node.acf?.thumb || 'noimage/img-card-thumb.jpg'}
                    {...(studio.node.acf?.detail_page ? { to: `/about/studios/${studio.node.slug}` } : {})}
                    place={studio.node.title?.rendered || ''}
                  />
                ))}
              </LoopCarousel>
            </FadeUpSection>
          </StudioWrap>
        </TopContent>
        <InquiryBlock top isStaticSp={isStaticSp} />
      </ContentsWrapper>
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query Index {
    site {
      siteMetadata {
        title
      }
    }
    shifterPickupProjects: shifterPickupProjects(title: { rendered: { eq: "allpickupprojects" } }) {
      acf {
        pickup {
          post_title
        }
      }
    }
    projectsJp: allShifterProjectsJpData(
      sort: { fields: date, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } } }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          slug
          acf {
            page_headline_ja
            client_abbr {
              ja
              en
            }
            services {
              post_title
            }
            kv
            kv_sp
            outline
            pickup
            product {
              ja
              en
            }
            thumb
          }
        }
      }
    }
    projects: allShifterProjectsData(
      sort: { fields: date, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } } }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          slug
          acf {
            page_headline_ja
            client_abbr {
              ja
              en
            }
            services {
              post_title
            }
            kv
            kv_sp
            outline
            pickup
            product {
              ja
              en
            }
          }
        }
      }
    }
    allShifterTopicsData(
      sort: { fields: acf___launch, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } } }
      limit: 6
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          acf {
            category
            thumb
            launch
          }
          slug
        }
      }
    }
    allShifterStudiosData(
      filter: { title: { rendered: { nin: ["dummy", null] } } }
      sort: { order: ASC, fields: acf___show_num }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          slug
          acf {
            thumb
            detail_page
          }
        }
      }
    }
  }
`;
