import React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import ImageAward from 'assets/images/top/facts/animation-award.png';
import ImageAwardLight from 'assets/images/top/facts/animation-award-light.png';
import { mq } from 'style/variables';

const slideBr = keyframes`
  0% {
    transform: translateY(-80px) translateX(-80px);
  }
  60% {
    transform: translateY(90px) translateX(90px);
  }
  100% {
    transform: translateY(90px) translateX(90px);
  }
`;

const Container = styled.div`
  width: 208px;
  height: 208px;
  background-image: url(${ImageAward});
  background-size: contain;
  position: relative;
  ${mq.onlysp} {
    transform: scale(calc(180 / 208));
    margin-left: -16px;
  }
`;

const LightWrapper = styled.div`
  width: 188px;
  height: 188px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 9px;
  overflow: hidden;
`;

const Light = styled.div`
  width: 185px;
  height: 162px;
  position: absolute;
  background-image: url(${ImageAwardLight});
  background-size: contain;
  animation-name: ${slideBr};
  animation-duration: 1.8s;
  animation-fill-mode: none;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-iteration-count: infinite;
  opacity: 0.5;
`;

const AnimationAward: React.FC = () => {
  return (
    <Container>
      <LightWrapper>
        <Light />
      </LightWrapper>
    </Container>
  );
};

export default AnimationAward;
