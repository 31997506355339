import * as React from 'react';
import styled from '@emotion/styled';
import { baseTransitionSecond, easeOut } from 'style/variables';
import { css } from '@emotion/core';
import { fadeup, fadeIn, fadeupBig, fadeScaleUp } from 'style/mixin';
import { useRef, useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';

type FadeUpItemProps = {
  delay?: number;
  noUp?: boolean;
  moveBig?: boolean;
  invokeInView?: boolean; // Add the new prop
  fadeScaleUp?: boolean;
  extraDuration?: number | 0;
};

const Container = styled('div')<FadeUpItemProps>`
  will-change: transform, opacity;
  body:not(.is-transition-animating) & {
    ${props => {
      const extraDuration = props.extraDuration ? props.extraDuration : 0;
      if (props.fadeScaleUp) {
        return css`
          animation: ${fadeScaleUp} ${baseTransitionSecond * 2 + extraDuration}s ${easeOut} both;
        `;
      }
      if (props.noUp && !props.moveBig) {
        return css`
          animation: ${fadeIn} ${baseTransitionSecond + extraDuration}s ${easeOut} both;
        `;
      }
      return css`
        animation: ${props.moveBig ? fadeupBig : fadeup} ${baseTransitionSecond * 2 + extraDuration}s ${easeOut} both;
      `;
    }}
    ${props => props.delay && `animation-delay: ${props.delay}s`}
  }
  &:not(.child-anime-ready) {
    * {
      animation-play-state: paused !important;
    }
  }
`;

const FadeUpItem: React.FC<FadeUpItemProps> = props => {
  const container = useRef<HTMLDivElement>(null);
  const [isInView, setIsInView] = useState(!props.invokeInView);
  const [isAnimationPlayed, setIsAnimationPlayed] = useState(props.invokeInView ? false : true);

  const resetStyle = () => {
    if (container.current && !props.moveBig) {
      setIsAnimationPlayed(true);
      container.current.style.animation = 'unset';
      container.current.style.transform = 'unset';
      container.current.style.willChange = 'unset';
      container.current.style.opacity = '1';
    }
  };

  useEffect(() => {
    if (container.current) {
      container.current.addEventListener('animationend', resetStyle);
    }
    if (!props.invokeInView) {
      setIsInView(true);
    }
    return () => {
      if (container.current) {
        container.current.removeEventListener('animationend', resetStyle);
      }
    };
  }, [props.invokeInView]);

  return (
    <InView
      onChange={inView => {
        if (props.invokeInView && inView) {
          setIsInView(true);
        }
      }}
      triggerOnce
    >
      <Container
        {...props}
        ref={container}
        style={{ animationPlayState: isInView ? 'running' : 'paused' }}
        className={isAnimationPlayed ? 'child-anime-ready' : ''}
      >
        {props.children}
      </Container>
    </InView>
  );
};

FadeUpItem.defaultProps = {
  invokeInView: false, // Set the default value for the new prop
};

export default FadeUpItem;
