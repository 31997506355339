import React from 'react';
import styled from '@emotion/styled';
import bgImg from 'assets/images/top/facts/animation-walk-bg.png';
import MaskImg from 'assets/images/top/facts/animation-walk-mask.png';
import ImageLeg from 'assets/images/top/facts/animation-walk-leg.png';
import { mq } from 'style/variables';

const Container = styled.div`
  position: relative;
  width: 169px;
  height: 169px;
  overflow: hidden;
  border-radius: 50%;
  background-image: url(${MaskImg});
  background-position: 0 0;
  background-size: contain;
  ${mq.onlysp} {
    transform: scale(calc(180 / 176));
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url(${bgImg});
    background-repeat: repeat-x;
    background-size: 341px 147px;
    background-position-y: 30px;
    animation: bgLoop 15s linear infinite;
  }

  @keyframes bgLoop {
    from {
      background-position: -341px 30px;
    }
    to {
      background-position: 0 30px;
    }
  }
`;

const width = 3440 / (288 / 80); // 画像のwidth / (画像のheight / 表示するサイズheight)

const Legs = styled.div`
  width: ${width / 6}px; // heightの79を元に調整
  height: 79px;
  position: absolute;
  top: 20px;
  left: 10px;
  background-image: url(${ImageLeg});
  background-repeat: no-repeat;
  background-size: cover;
  animation: parapara 2s steps(6) infinite;

  @keyframes parapara {
    to {
      background-position: -${width}px 0;
    }
  }
`;

const AnimationWalk: React.FC = () => {
  return (
    <Container>
      <Legs />
    </Container>
  );
};

export default AnimationWalk;
